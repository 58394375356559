import React, { PropsWithChildren } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Carousal: React.FC<PropsWithChildren> = ({ children }) => {
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        speed: 500,
        focusOnSelect: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        swipeToSlide: true,
        dots: true,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "50px",

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 500,
                    dots: true,
                    centerMode: true,
                    centerPadding: "0px",
                    infinite: true,
                },
            },
        ],
    };
    return (
        <>
            <Slider {...settings}>{children}</Slider>
        </>
    );
};
