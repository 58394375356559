import Image from "next/image";
import React, { FC } from "react";
import { IoChevronForwardCircleOutline } from "react-icons/io5";

interface CaseStudyBannerType {
    id: number;
    bannerimg: string;
    tag: string;
    title: string;
    link: string;
    containerStyle?: string;
}

const CaseStudyBanner: FC<CaseStudyBannerType> = ({ bannerimg, tag, title, link, containerStyle = "" }) => {
    return (
        <div className={`casestudy-banner min-h-fit max-w-md sm:max-w-none sm:w-96 md:w-2/5 lg:max-w-md lg:pb-9 relative ${containerStyle}`}>
            <a href={link} target="_blank" rel="noreferrer" className="hover:no-underline">
                <div>
                    <Image src={bannerimg} alt={`${tag} logo`} width={448} height={212} objectFit="cover" />
                    <div className="px-6 py-3">
                        <p className="banner-tag my-3">{tag}</p>
                        <h4 className="banner-title m-0 my-2 mb-6 pb-6 h-32 lg:mb-0 lg:pb-0 ">{title}</h4>
                        <div className="flex justify-end gap-1 mb-2 items-center mt-0 absolute bottom-5 right-5 lg:bottom-3 lg:py-4" style={{ textDecorationColor: "#3364FA" }}>
                            <p className="readnow-text cursor-pointer hover:underline">Read Now</p>
                            <IoChevronForwardCircleOutline size={23} style={{ color: "#3364FA" }} />
                        </div>
                    </div>
                </div>
            </a>
            <style jsx>{`
                .casestudy-banner {
                    font-family: "Google Sans";
                    font-style: normal;
                    letter-spacing: 0.02em;
                    background-color: #eef2f9;
                    border-radius: 5px;
                }
                .casestudy-banner .banner-tag {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 19px;
                    color: #838383;
                    width: fit-content;
                    padding: 2px;
                    border: 1px solid #838383;
                }
                .casestudy-banner .banner-title {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 25px;
                    color: #1a202c;
                }
                .readnow-text {
                    font-family: "Google Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 25px;
                    letter-spacing: 0.02em;
                    color: #3364fa;
                }
                @media (max-width: 35rem) {
                    .casestudy-banner {
                        height: fit-content;
                    }
                    .casestudy-banner .banner-title {
                        font-size: 16px;
                    }
                    .readnow-text {
                        font-size: 16px;
                    }
                    .casestudy-banner .banner-tag {
                        font-size: 12px;
                    }
                }
            `}</style>
        </div>
    );
};
export default CaseStudyBanner;
