import React, { FC } from "react";
import NewLayout from "@components/NewLayout";
import CaseStudyBanner from "@components/CaseStudyComponents/CaseStudyBanner";
import "keen-slider/keen-slider.min.css";
import useSlider from "@hooks/useSlider";
import { Carousal } from "@components/carousal";

export const CASE_STUDIES = [
    {
        id: 1,
        bannerimg: "https://assets.gallabox.com/gb-home/qatar-insurance-company/header-image.png",
        tag: "Qatar Insurance Company",
        title: "How Qatar Insurance Company Doubled Lead Generation with Gallabox",
        link: "/case-study/qatar-insurance-company",
    },
    {
        id: 2,
        bannerimg: "https://assets.gallabox.com/gb-home/property2x/property2x-header.png",
        tag: "Real estate",
        title: "How Property2X Used Gallabox to Achieve a 300% Increase in Gross Profit",
        link: "/case-study/property2x",
    },
    {
        id: 3,
        bannerimg: "https://assets.gallabox.com/gb-home/hugg/hugg-header.png",
        tag: "D2C",
        title: "Maximizing Conversions and Streamlining Support : Hugg's Transformation",
        link: "/case-study/hugg",
    },
    {
        id: 4,
        bannerimg: "https://assets.gallabox.com/gb-home/go-ireland/goireland-header.png",
        tag: "Education",
        title: "How GoIreland Used Gallabox to Increase Conversions by 39%",
        link: "/case-study/goireland",
    },

    {
        id: 5,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/case-study-banner-1.png",
        tag: "Travel & Tourism",
        title: "LivingStone Stays Relocated Their Communications From IVR..",
        link: "/case-study/livingstone-stay",
    },
    {
        id: 6,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/case-study-banner-3.png",
        tag: "HR & Professional Service",
        title: "Jobstars India Deployed a Multilingual Chatbot to Handle 60%..",
        link: "/case-study/jobstars-india",
    },
    {
        id: 7,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/vcare/vcare-banner-img.png",
        tag: "Healthcare",
        title: "VCare increases their lead generation, conversion rate, and the quality of..",
        link: "/case-study/vcare",
    },
    {
        id: 8,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/numberwale/numberwale-banner-img.png",
        tag: "Telecom",
        title: "Numberwale increases their sales by nearly 25% with the help of Gallabox.",
        link: "/case-study/numberwale",
    },
    {
        id: 9,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/propleaf/propleaf-banner-img.png",
        tag: "Real estate",
        title: "PropLeaf Realty Tech increases their connect ratio with customers by 25%.",
        link: "/case-study/propleaf",
    },
    {
        id: 10,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/curioed/curioed-banner-img-new.png",
        tag: "Education",
        title: "CurioEd increases their engagement rate by 40%.",
        link: "/case-study/curioed",
    },
    {
        id: 11,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/case-study-banner-2.png",
        tag: "Retail, D2C",
        title: "Sattvic Stores Reduced RTO by 10% within a month of using Gallabox..",
        link: "/case-study/sattvic-store",
    },
];
export const CaseStudyHomePageInput = CASE_STUDIES.slice(0, 3);

const CaseStudyPages: FC = () => {
    const { refCallback, slider } = useSlider();
    const blueboxslider = [
        {
            profileimg: "https://assets.gallabox.com/gb-home/case-study/kamalhasan.png",
            name: "A.Kamalahasan",
            position: "Managing Director for Jobstar",
            content:
                "Gallabox is our official chat partner. The simple & elegant automated chat response system made our business processes easy & hassle free. We are happy to use Gallabox, as all our business needs were fulfilled under one roof. Thank you team for your extended support.",
        },
        {
            profileimg: "https://assets.gallabox.com/gb-home/case-study/prakash.png",
            name: "Prakash G",
            position: "Managing Director",
            content:
                "Awesome support team! Gallabox's Customer Success team is very responsive and ready to help if we have any questions. This is a huge differentiator for us since we deal with hundreds of live customer conversations and timely support is needed.",
        },
        {
            profileimg: "https://assets.gallabox.com/gb-home/case-study/Rajaram.png",
            name: "Rajaram R",
            position: "Managing Director",
            content:
                "Easy to use customer communication platform! We setup within 48 hrs. Gallabox is very useful for small and medium enterprises, you can customise/ deploy bots without any coding experience - simple drag and drop interface.",
        },
        {
            profileimg: "https://assets.gallabox.com/gb-home/case-study/jayadeep.png",
            name: "Jaydeep P",
            position: "Managing Director",
            content:
                "Great tool if you want to pull info from your CRM and talk to your customers on WhatsApp. I use their Broadcast feature to send offers - and keep track of my campaigns.  I am delighted to see the progress Gallabox is making.",
        },
    ];

    return (
        <NewLayout
            title="Customer Case Studies | Gallabox"
            description="See how our customers of all sizes have grown using Gallabox Shared Inbox, WhatsApp No-Code Chatbot and WhatsApp Broadcasts  to increase their lead generation and conversion rate."
            canonical="https://gallabox.com/case-study"
        >
            <header className="mt-3 grid lg:mt-24 casestudy-main-header">
                <h1 className="grid place-content-center text-center">
                    <span className="top-text">Customer Case Studies</span>
                    <span className="bottom-text">Growing with Gallabox</span>
                </h1>
            </header>
            <div className="px-3 pt-5 pb-7 mt-8 lg:px-24 bg-gray-50">
                <section style={{ backgroundColor: "#2C3B6B" }} className="p-0 lg:w-full case-study-carousal">
                    <div className="max-w-6xl mx-auto lg:py-10">
                        <p className="pt-5 text-center lg:text-left pt-0 text-white font-bold text-xl lg:text-3xl mb-3 lg:ml-32 ">Featured</p>
                        <Carousal>
                            <div>
                                <div className="lg:flex mx-auto gap-9" style={{ width: "90%" }}>
                                    <img src="https://assets.gallabox.com/gb-home/itees/header.png" alt="" className="carousal-img" />

                                    <p className="case-study-carousal-content  grid lg:inline py-20">
                                        How Gallabox Improved Patient Experience at ITEES Eye Hospital <br />
                                        <a href="/case-study/itees" target="_blank" rel="noreferrer">
                                            <span className="redirect ">Read now</span>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </Carousal>
                    </div>
                </section>
                <main className="max-w-6xl mx-auto flex items-center flex-col no-wrap sm:flex-row gap-6 justify-center sm:flex-wrap mt-14 lg:gap-16 ">
                    {CASE_STUDIES.map((x) => {
                        return <CaseStudyBanner id={x.id} key={x.id} bannerimg={x.bannerimg} tag={x.tag} title={x.title} link={x.link} />;
                    })}
                </main>
            </div>
            <section className="signup bg-blue-color px-7 py-5 lg:mt-6 stroke-pattern">
                <div className="container mx-auto relative py-9">
                    <div className="lg:slides-block">
                        <button
                            className="slider-btn-left"
                            onClick={() => {
                                slider.current?.prev();
                            }}
                        >
                            <img src="https://assets.gallabox.com/gb-home/case-study/left.png" alt="" />
                        </button>
                        <div className="">
                            <div ref={refCallback} className="keen-slider">
                                {blueboxslider.map((value: { profileimg: string; name: string; position: string; content: string }, i: number) => {
                                    return (
                                        <div className="keen-slider__slide case-study-bluebox-slider lg:p-8" key={i}>
                                            <div className="flex flex-col lg:flex-row gap-3 lg:gap-5 justify-center items-center p-5">
                                                <div className="lg:w-1/4 flex-col flex lg:gap-2">
                                                    <img src={value.profileimg} className="mx-auto mb-2" alt="" />
                                                    <p className="slider-profile-name mx-auto">{value.name}</p>
                                                    <p className="slider-profile-about">{value.position}</p>
                                                </div>
                                                <div className="lg:w-3/4">
                                                    <p className="slider-content">{value.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <button
                            className="slider-btn-right"
                            onClick={() => {
                                slider.current?.next();
                            }}
                        >
                            <img src="https://assets.gallabox.com/gb-home/case-study/right.png" alt="" />
                        </button>
                    </div>
                </div>

                <div className="flex flex-col gap-8 my-5 lg:my-0 lg:flex-row lg:gap-16 items-center justify-center">
                    <div className="flex gap-3 items-center justify-center pl-4 lg:pl-0">
                        <img src="https://assets.gallabox.com/gb-home/case-study/Robot.png" alt="" />
                        <p className="text-white">Want similar results?</p>
                    </div>
                    <div>
                        <a className="jsx-1957620388 signup-btn text-blue-color bg-white p-4" href="https://app.gallabox.com/signup">
                            Sign Up For A Free Trial
                        </a>
                        {/* <CtaButton text="Sign Up For A Free Trial" type="large" /> */}
                    </div>
                </div>
            </section>
            <style jsx>{`
                .casestudy-main-header {
                    background-image: url("https://assets.gallabox.com/gb-home/case-study/landing-banner-case-study.png");
                    background-color: #eaefff;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100vw;
                    height: 60vh;
                }
                .casestudy-main-header h1 {
                    font-family: Google Sans;
                    font-style: normal;
                    letter-spacing: 0.02em;
                    color: #162c6f;
                }
                .casestudy-main-header .top-text {
                    font-weight: 500;
                    font-size: 30px;
                    line-height: 38px;
                }
                .casestudy-main-header .bottom-text {
                    font-weight: 700;
                    font-size: 50px;
                    line-height: 64px;
                }
                .case-study-bluebox-slider {
                    font-family: "Google Sans";
                    font-style: normal;
                    letter-spacing: 0.05em;
                    color: #4a5568;
                    background-color: #ffffff;
                    box-shadow: 2.01005px 2.01005px 10.0503px rgba(51, 100, 250, 0.2), inset 0px 0px 10.0503px rgba(122, 152, 244, 0.1);
                    border-radius: 10.0503px;
                }
                .slider-profile-name {
                    font-weight: 700;
                    font-size: 22.1106px;
                    width: fit-content;
                    line-height: 28px;
                }
                .slider-content {
                    font-style: italic;
                    font-weight: 500;
                    font-size: 26.1307px;
                    line-height: 40px;
                    width: fit-content;
                }
                .slider-profile-about {
                    font-weight: 400;
                    font-size: 17.9834px;
                    line-height: 23px;
                    text-align: center;
                }
                .case-study-tag-filter {
                    font-family: "Google Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14.9744px;
                    line-height: 19px;
                    letter-spacing: 0.02em;
                    color: #2d3748;
                }
                .case-study-filter-checkbox {
                    width: 18px;
                    height: 18px;
                    background-color: #ffffff;
                    border: 1.5px solid #0083ff;
                    border-radius: 2px;
                }
                .case-study-carousal {
                    border-radius: 10px;
                }
                .case-study-carousal-content {
                    font-family: "Google Sans";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 40px;
                    letter-spacing: 0.02em;
                    color: #ffffff;
                }
                .case-study-carousal .redirect {
                    font-family: "Google Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 19px;
                    letter-spacing: 0.02em;
                    color: #bababa;
                }
                .case-study-carousal .redirect::after {
                    content: url("https://assets.gallabox.com/gb-home/case-study/redirect-arrow.png");
                    position: relative;
                    left: 4px;
                    top: 2px;
                }
                .carousal-img {
                    width: 530px;
                    background-color: #d9d9d9;
                    border-radius: 4.99649px;
                }
                .filter-title-case-study {
                    font-family: "Google Sans";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 19px;
                    text-align: center;
                    letter-spacing: 0.02em;
                    color: #838383;
                }
                .slider-btn-right {
                    position: absolute;
                    top: 150px;
                    right: -35px;
                    z-index: 5;
                }
                .slider-btn-left {
                    position: absolute;
                    top: 150px;
                    left: -35px;
                    z-index: 5;
                }
                .slide-count-case-study {
                    width: 60px;
                    height: 60px;
                    color: #3364fa;
                    background-color: #ffffff;
                    border: 1px solid rgba(51, 100, 250, 0.1);
                    border-radius: 50%;
                    box-shadow: 2px 2px 10px rgba(51, 100, 250, 0.2), inset 0px 0px 10px rgba(122, 152, 244, 0.1);
                }
                .left-arrow {
                    width: 30px;
                    height: 30px;
                    background-color: #ffffff;
                    border: 1px solid rgba(51, 100, 250, 0.1);
                    border-radius: 50%;
                    box-shadow: 2px 2px 10px rgba(51, 100, 250, 0.2), inset 0px 0px 10px rgba(122, 152, 244, 0.1);
                }
                .right-arrow {
                    width: 30px;
                    height: 30px;
                    background-color: #ffffff;
                    border: 1px solid rgba(51, 100, 250, 0.1);
                    border-radius: 50%;
                    box-shadow: 2px 2px 10px rgba(51, 100, 250, 0.2), inset 0px 0px 10px rgba(122, 152, 244, 0.1);
                }

                @media (max-width: 35rem) {
                    .casestudy-main-header {
                        background-size: contain;
                        height: 50vh;
                    }
                    .casestudy-main-header .top-text {
                        font-size: 20px;
                    }
                    .casestudy-main-header .bottom-text {
                        font-size: 30px;
                        padding-inline: 1rem;
                        line-height: 35px;
                        margin-top: 0.7rem;
                    }
                    .case-study-carousal {
                        border-radius: 10px;
                    }
                    .carousal-img {
                        width: 100%;
                    }
                    .case-study-carousal-content {
                        font-size: 18px;
                        line-height: 28px;
                        padding-inline: 1rem;
                        margin-top: 0.5rem;
                        margin-bottom: 1rem;
                    }
                    .slide-count-case-study {
                        width: 50px;
                        height: 50px;
                    }
                    .left-arrow {
                        width: 30px;
                        height: 30px;
                    }
                    .right-arrow {
                        width: 30px;
                        height: 30px;
                    }
                    .slider-profile-name {
                        font-size: 19px;
                        line-height: 30px;
                    }
                    .slider-profile-about {
                        font-size: 18px;
                        line-height: 23px;
                    }
                    .slider-content {
                        font-size: 16px;
                        line-height: 23px;
                    }
                    .slider-btn-right {
                        top: 180px;
                        right: -5px;
                        width: 3rem;
                    }
                    .slider-btn-left {
                        top: 180px;
                        left: -5px;
                        width: 3rem;
                    }
                }
            `}</style>
        </NewLayout>
    );
};

export default CaseStudyPages;
