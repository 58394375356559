import React from "react";
import "keen-slider/keen-slider.min.css";
import { KeenSliderInstance, useKeenSlider } from "keen-slider/react";

interface UseSliderProps {
    autoPlay?: boolean;
    autoPlayInterval?: number;
}

interface UseSliderReturn {
    loaded: boolean;
    refCallback: (element: HTMLDivElement) => void;
    currentSlide: number;
    slider: React.MutableRefObject<KeenSliderInstance | null>;
}

const useSlider = (props: UseSliderProps = {}): UseSliderReturn => {
    const { autoPlay, autoPlayInterval = 3000 } = props;
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [loaded, setLoaded] = React.useState(false);

    const [refCallback, slider] = useKeenSlider({
        initial: 0,
        loop: true,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setLoaded(true);
        },
    });

    React.useEffect(() => {
        if (!autoPlay) return;
        const interval = setInterval(function () {
            if (autoPlay && slider.current) {
                slider.current.next();
            }
        }, autoPlayInterval);
        return () => clearInterval(interval);
    }, [autoPlay, autoPlayInterval, currentSlide, slider]);

    return { loaded, refCallback, currentSlide, slider };
};

export default useSlider;
